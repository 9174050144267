import { openShareDialog, setDocumentShare } from 'demo/store/commonReducer'
import React from 'react'
import { HiEye } from 'react-icons/hi'
import { VscFilePdf, VscFileZip, VscFile } from 'react-icons/vsc'
import { useDispatch } from 'react-redux'

const BYTE = 1000
const getKB = (bytes) => Math.round(bytes / BYTE)

const FileIcon = ({ children }) => {
    return <span className="text-4xl">{children}</span>
}

const FileItem = (props) => {
    const dispatch = useDispatch()

    const {
        files,
        index,
        file,
        children,
        customise,
        datatable,
        showUploadTime,
    } = props
    const { type, name, display_name, size, url } = file

    const renderThumbnail = () => {
        const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp']
        const zipExtensions = ['zip']
        const pdfExtensions = ['pdf']

        const getFileExtension = () => {
            const parts = display_name?.split('.')
            return parts?.length > 1 ? parts[parts.length - 1] : ''
        }

        const fileExtension = getFileExtension()

        const isImageFile =
            type?.split('/')[0] === 'image' ||
            imageExtensions.includes(fileExtension?.toLowerCase())
        const isZipFile =
            type === 'application/zip' ||
            zipExtensions.includes(fileExtension?.toLowerCase())
        const isPdfFile =
            type === 'application/pdf' ||
            pdfExtensions.includes(fileExtension?.toLowerCase())

        if (isImageFile) {
            return (
                <img
                    className="upload-file-image"
                    src={url ? url : URL.createObjectURL(file)}
                    alt={`file preview ${name}`}
                />
            )
        }

        if (isZipFile) {
            return (
                <FileIcon>
                    <VscFileZip />
                </FileIcon>
            )
        }

        if (isPdfFile) {
            return (
                <FileIcon>
                    <VscFilePdf />
                </FileIcon>
            )
        }

        return (
            <FileIcon>
                <VscFile />
            </FileIcon>
        )
    }

    return (
        <>
            <div
                className={`${datatable ? 'datatable-file' : 'upload-file'} ${
                    customise && !datatable ? 'max-w-[300px]' : ''
                }`}
            >
                <div className="flex">
                    {!customise ? (
                        <div className="relative">
                            <div
                                className="upload-file-thumbnail pointer relative group"
                                onClick={() => {
                                    const fileList = [...files]

                                    const clickedFile = fileList.splice(
                                        index,
                                        1
                                    )[0]

                                    const sortedFiles = fileList.sort(
                                        (a, b) => b.index_image - a.index_image
                                    )

                                    const beforeClicked = sortedFiles.filter(
                                        (file) =>
                                            file.index_image <
                                            clickedFile.index_image
                                    )
                                    const afterClicked = sortedFiles.filter(
                                        (file) =>
                                            file.index_image >=
                                            clickedFile.index_image
                                    )

                                    const reorderedFiles = [
                                        clickedFile,
                                        ...beforeClicked,
                                        ...afterClicked,
                                    ]

                                    dispatch(openShareDialog())
                                    dispatch(setDocumentShare(reorderedFiles))
                                }}
                            >
                                {renderThumbnail()}
                                <div className="absolute inset-2 bg-gray-900/[.7] group-hover:flex hidden text-xl items-center justify-center">
                                    <span className="text-gray-100 hover:text-gray-300 cursor-pointer p-1.5">
                                        <HiEye />
                                    </span>
                                </div>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                    <div className="upload-file-info">
                        <h6
                            className={
                                !customise
                                    ? `upload-file-name ${
                                          url
                                              ? 'two-line --break-word'
                                              : 'one-line'
                                      }`
                                    : 'text-sm'
                            }
                        >
                            {name || display_name}
                        </h6>
                        {size && (
                            <span
                                className={
                                    !customise
                                        ? 'upload-file-size'
                                        : 'text-xs mt-1'
                                }
                            >
                                {getKB(size)} kb
                            </span>
                        )}
                        {showUploadTime && file?.created_at && (
                            <span className="mt-1" style={{ fontSize: '13px' }}>
                                Upload at {file?.created_at}
                            </span>
                        )}
                    </div>
                </div>
                {children}
            </div>
        </>
    )
}

export default FileItem
