import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import apiService from '../../services/api.service'

export const getProductList = createAsyncThunk(
    'getProductList',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.getProductList(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const getCartList = createAsyncThunk(
    'getCartList',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.getCartList(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const addCart = createAsyncThunk(
    'addCart',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.addCart(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const updateCart = createAsyncThunk(
    'updateCart',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.updateCart(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const clearCart = createAsyncThunk(
    'clearCart',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.clearCart(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const removeCart = createAsyncThunk(
    'removeCart',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.removeCart(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const createOrder = createAsyncThunk(
    'createOrder',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.createOrder(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

const currentYear = new Date().getFullYear()

export const initialData = {
    productPackageList: [],
    productAlacarteList: [],
    cartList: {},
    formLoading: false,
    createLoading: false,
    updateLoading: false,
    removeLoading: false,
    clearLoading: false,
    productLoading: false,
    addLoading: null,

    filterBrand: [],
    filterModel: [],
    filterCategory: [],
    filterYear: [1990, currentYear],

    cartSort: {
        activePage: 1,
        start: 0,
        length: 10,
        search: '',
        total: 0,
        type: 'user',
    },
}

function groupTintedProducts(products, type) {
    const grouped = {}

    const filteredProducts = products.filter(
        (prod) => prod.is_package.toString() === type.toString()
    )

    filteredProducts.forEach((prod) => {
        const series = prod.series.category?.toLowerCase().replace(/\s+/g, '_')
        const seriesName = prod.series.name?.toLowerCase().replace(/\s+/g, '_')
        const bodyType = prod.category.name?.toLowerCase().replace(/\s+/g, '_')

        if (!grouped[series]) {
            grouped[series] = {}
        }
        if (!grouped[series][seriesName]) {
            grouped[series][seriesName] = {
                standard: [],
                luxury: [],
                suv: [],
                mpv: [],
            }
        }
        if (grouped[series][seriesName][bodyType] === undefined) {
            grouped[series][seriesName][bodyType] = []
        }

        if (type === 'false') {
            const parts = prod.properties.package_detail

            Object.keys(parts).forEach((part) => {
                const normalizedPart = part.toLowerCase().replace(/ /g, '_')
                if (!grouped[series][seriesName][bodyType][normalizedPart]) {
                    grouped[series][seriesName][bodyType][normalizedPart] = []
                }
                // Add product to the appropriate part
                grouped[series][seriesName][bodyType][normalizedPart].push(prod)
            })

            return
        }

        grouped[series][seriesName][bodyType].push(prod)
    })

    return grouped
}

function groupProducts(products, type) {
    const grouped = {}

    const filteredProducts = products.filter(
        (prod) => prod.is_package.toString() === type.toString()
    )

    filteredProducts.forEach((prod) => {
        const bodyType = prod.category.name.toLowerCase().replace(/\s+/g, '_')

        if (!grouped[bodyType]) {
            grouped[bodyType] = []
        }

        grouped[bodyType].push(prod)
    })

    return grouped
}

const dataSlice = createSlice({
    name: 'cart/data',
    initialState: initialData,
    reducers: {
        setCartSort: (state, action) => {
            state.cartSort = action.payload
        },
        setListLoading: (state, action) => {
            state.listLoading = action.payload
        },
        setAddLoading: (state, action) => {
            state.addLoading = action.payload
        },
        setProductLoading: (state, action) => {
            state.productLoading = action.payload
        },

        setFilterBrand: (state, action) => {
            state.filterBrand = action.payload
        },
        setFilterModel: (state, action) => {
            state.filterModel = action.payload
        },
        setFilterCategory: (state, action) => {
            state.filterCategory = action.payload
        },
        setFilterYear: (state, action) => {
            state.filterYear = action.payload
        },
    },
    extraReducers: {
        [getProductList.rejected]: (state) => {
            state.productLoading = false
        },
        [getProductList.fulfilled]: (state, action) => {
            state.productLoading = false

            const service = action.payload.data.service_type

            switch (service) {
                case 'tinted':
                    state.productPackageList =
                        action.payload.data.packages?.length > 0
                            ? groupTintedProducts(
                                  action.payload.data.packages,
                                  'true'
                              )
                            : []
                    state.productAlacarteList =
                        action.payload.data.packages?.length > 0
                            ? groupTintedProducts(
                                  action.payload.data.packages,
                                  'false'
                              )
                            : []
                    break
                case 'ppf':
                    state.productPackageList = groupProducts(
                        action.payload.data.packages,
                        'true'
                    )
                    state.productAlacarteList = groupProducts(
                        action.payload.data.packages,
                        'false'
                    )
                    break
                case 'wrapping':
                    state.productAlacarteList = groupProducts(
                        action.payload.data.packages,
                        'false'
                    )
                    break
                case 'coating':
                    state.productPackageList = groupProducts(
                        action.payload.data.packages,
                        'true'
                    )
                    state.productAlacarteList = groupProducts(
                        action.payload.data.packages,
                        'false'
                    )
                    break
                case 'accessory':
                    state.productAlacarteList = action.payload.data.packages
                    break
                default:
                    break
            }
        },
        [getProductList.pending]: (state) => {
            state.productLoading = true
        },

        [getCartList.rejected]: (state) => {
            state.listLoading = false
        },
        [getCartList.fulfilled]: (state, action) => {
            state.listLoading = false
            state.cartList = action.payload.data
        },
        [getCartList.pending]: (state) => {
            state.listLoading = true
        },

        [clearCart.rejected]: (state) => {
            state.clearLoading = false
        },
        [clearCart.fulfilled]: (state, action) => {
            state.clearLoading = false
        },
        [clearCart.pending]: (state) => {
            state.clearLoading = true
        },

        [addCart.rejected]: (state) => {
            state.addLoading = null
        },
        [addCart.fulfilled]: (state, action) => {
            state.addLoading = null
        },
        [addCart.pending]: (state) => {
            // state.addLoading = true
        },

        [updateCart.rejected]: (state) => {
            state.updateLoading = false
        },
        [updateCart.fulfilled]: (state, action) => {
            state.updateLoading = false
        },
        [updateCart.pending]: (state) => {
            state.updateLoading = true
        },

        [removeCart.rejected]: (state) => {
            state.removeLoading = false
        },
        [removeCart.fulfilled]: (state, action) => {
            state.removeLoading = false
        },
        [removeCart.pending]: (state) => {
            state.removeLoading = true
        },

        [createOrder.rejected]: (state) => {
            state.createLoading = false
        },
        [createOrder.fulfilled]: (state, action) => {
            state.createLoading = false
        },
        [createOrder.pending]: (state) => {
            state.createLoading = true
        },
    },
})

export const {
    setCartSort,
    setListLoading,
    setProductLoading,
    setToggle,
    clearUserList,
    setAddLoading,
    setFilterBrand,
    setFilterModel,
    setFilterCategory,
    setFilterYear,
} = dataSlice.actions

export default dataSlice.reducer
