import { useFormikContext } from 'formik'
import React, { forwardRef } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const RichTextEditor = forwardRef((props, ref) => {
    const { disabled } = props
    return (
        <div className={`rich-text-editor --${disabled ? 'disabled' : ''}`}>
            <ReactQuill ref={ref} {...props} />
        </div>
    )
})

export default RichTextEditor
