import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import apiService from 'services/api.service'
import authService from 'services/auth.service'

export const login = createAsyncThunk('login', async (payload, thunkAPI) => {
    try {
        const response = await authService.login(payload)
        return response
    } catch (error) {
        return thunkAPI.rejectWithValue(error)
    }
})

export const getNewOrderCount = createAsyncThunk(
    'getNewOrderCount',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.getNewOrderCount(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const deleteMedia = createAsyncThunk(
    'deleteMedia',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.deleteMedia(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const initialData = {
    token: '',
    username: '',
    role: [],
    accessRole: '',
    confirmData: {},
    exportData: [],
    documentShare: [],
    documentPreview: [],
    exportHistoryData: [],
    newOrderCount: 0,
    deleteLoading: false,
    heicLoading: false,
    isOpenShareDialog: false,
    isOpenFileDialog: false,
    isOpenConfirmDialog: false,
    isOpenFilterDrawer: false,
    isOpenAddProduct: false,

    periodOption: [
        { label: 'Days', value: 'Days' },
        { label: 'Weeks', value: 'Weeks' },
        { label: 'Months', value: 'Months' },
        { label: 'Years', value: 'Years' },
        { label: 'Lifetime', value: 'Lifetime' },
    ],
}

const dataSlice = createSlice({
    name: 'common',
    initialState: initialData,
    reducers: {
        getToken: (state) => {
            state.token = localStorage.getItem('token')
        },
        getUsername: (state) => {
            state.username = localStorage.getItem('username')
        },
        getRole: (state) => {
            state.role = [localStorage.getItem('role')]
            state.accessRole = localStorage.getItem('role')
        },
        openConfirmDialog: (state) => {
            state.isOpenConfirmDialog = true
        },
        closeConfirmDialog: (state) => {
            state.isOpenConfirmDialog = false
        },
        setConfirmData: (state, action) => {
            state.confirmData = action.payload
        },
        setNewOrderCount: (state, action) => {
            state.newOrderCount = action.payload
        },
        setExportData: (state, action) => {
            state.exportData = action.payload
        },
        setExportHistoryData: (state, action) => {
            state.exportHistoryData = action.payload
        },
        setDocumentPreview: (state, action) => {
            state.documentPreview = action.payload
        },
        setDocumentShare: (state, action) => {
            state.documentShare = action.payload
        },
        setHeicLoading: (state, action) => {
            state.heicLoading = action.payload
        },
        openShareDialog: (state, action) => {
            state.isOpenShareDialog = true
        },
        closeShareDialog: (state) => {
            state.isOpenShareDialog = false
        },
        openFileDialog: (state, action) => {
            state.isOpenFileDialog = true
        },
        closeFileDialog: (state) => {
            state.isOpenFileDialog = false
        },
        openFilterDrawer: (state) => {
            state.isOpenFilterDrawer = true
        },
        closeFilterDrawer: (state) => {
            state.isOpenFilterDrawer = false
        },
        openAddProduct: (state) => {
            state.isOpenAddProduct = true
        },
        closeAddProduct: (state) => {
            state.isOpenAddProduct = false
        },
    },
    extraReducers: {
        [login.rejected]: (state) => {
            state.loginLoading = false
        },
        [login.fulfilled]: (state, action) => {
            state.loginLoading = false
        },
        [login.pending]: (state) => {
            state.loginLoading = true
        },
        [getNewOrderCount.rejected]: (state) => {},
        [getNewOrderCount.fulfilled]: (state, action) => {
            state.newOrderCount = action.payload.data.pending_orders
        },
        [getNewOrderCount.pending]: (state) => {},
        [deleteMedia.rejected]: (state) => {
            state.deleteLoading = false
        },
        [deleteMedia.fulfilled]: (state, action) => {
            state.deleteLoading = false
        },
        [deleteMedia.pending]: (state) => {
            state.deleteLoading = true
        },
    },
})

export const {
    getToken,
    getUsername,
    getRole,
    openConfirmDialog,
    openShareDialog,
    openFilterDrawer,
    openAddProduct,
    openFileDialog,
    closeConfirmDialog,
    closeShareDialog,
    closeFilterDrawer,
    closeAddProduct,
    closeFileDialog,
    setConfirmData,
    setExportData,
    setExportHistoryData,
    setHeicLoading,
    setNewOrderCount,
    setDocumentPreview,
    setDocumentShare,
} = dataSlice.actions

export default dataSlice.reducer
